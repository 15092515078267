/*!
 * SITE main application
 * @copyright Copyright 2019
 *
 * Tapio Löytty, <tapsa@orange-media.fi>
 * Web: www.orange-media.fi
 * Goran Ilic, <ja@ich-mach-das.at>
 * Web: www.ich-mach-das.at
 */

// Core
import { create, boot } from 'core';
import * as utils from 'utils';

import hideNotificationAlerts from 'plugins/hideNotificationAlerts';
import imgLazyLoad from 'plugins/imgLazyLoad';
import initializeLightGallery from 'plugins/initializeLightGallery';
import loadOSMap from 'plugins/loadOSMaps';
import splideSlider from 'plugins/initializeSplide';
import processForm from 'plugins/formSubmitHandler';
import scrollToHash from 'plugins/scrollToHash';
import scrollToTop from 'plugins/scrollToTop';
import stickyElement from 'plugins/stickyElement';
import toggleHamburgerMenu from 'plugins/toggleHamburgerMenu';

// Import Vendor
//import AOS from 'aos';
import 'vendor/svgxuse';
import 'vendor/klaro-no-css';
import { klaro } from './vendor/klaro-no-css';

//=============================================================
// Config
//=============================================================

const config = {};

//=============================================================
// Options
//=============================================================

const defaultOptions = {
  rootUrl: './',
};

//=============================================================
// Bind API
//=============================================================

const API = create(config);

API.use(hideNotificationAlerts);
API.use(imgLazyLoad);
API.use(initializeLightGallery);
API.use(loadOSMap);
API.use(processForm);
API.use(scrollToHash);
API.use(scrollToTop);
API.use(splideSlider);
API.use(stickyElement);
API.use(toggleHamburgerMenu);

API.page = document.body.dataset;

API.init = (options) => {
  // Set runtime options
  API.options = Object.assign({}, defaultOptions, options);

  API.tasks.init.run();

  //let manager = klaro.getManager();

  // Init gallery plugin
  const galleries = document.querySelectorAll('.items--images');
  for (let gallery of galleries) {
    API.initializeLightGallery(gallery);
  }
  // Initialize sticky header
  API.stickyElement();
  //Scroll top top
  API.scrollToTop('#back-to-top');
  // Splide slider
  API.splideSlider('.splide');

  // Handle form validation and submit
  const forms = document.querySelectorAll('.form-validate');
  forms.forEach((form) => {
    let errorList = form.querySelector('ul.alerts-validate');

    API.processForm(form, errorList).validateBeforeSubmit();

    if (form.classList.contains('form-ajax'))
      API.processForm(form, errorList).submitWithAjax();
  });

  // Init google map
  const maps = document.querySelectorAll('.map');
  let manager = window.klaro.getManager(klaroConfig);
  if (maps.length > 0 && manager.getConsent('leaflet')) {
    maps.forEach((item) => {
      let map = API.loadOSMap();
      let mapData = JSON.parse(item.dataset.map);

      map.createMap(item);
      map.addMarkers(mapData);
    });
  }

  // Init mobile menu icon
  API.toggleHamburgerMenu('.navigation--trigger', '#main-navbar');

  API.tasks.ready.run();

  window.addEventListener('unhandledrejection', function (event) {
    console.warn(
      'WARNING: Unhandled promise rejection. Reason: ' + event.reason,
      event
    );
  });
};

//=============================================================
// Boot API
//=============================================================

boot(API);
