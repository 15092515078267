import { animateScrollTo } from 'utils';

export default function plugin(API) {
  API.scrollToHash = (hash) => {
    const navbar = document.querySelector('.container--navbar');
    const special = document.querySelector('.container--special');
    let navbarHeight = 0;
    let specialHeight = 0;

    if (navbar) {
      if (document.body.classList.contains('is-navbar-sticky')) {
        navbarHeight = -40;
      } else {
        navbarHeight = 110;
      }
    }

    if (special) {
      specialHeight = special.offsetHeight || 0;
    }

    console.log(navbarHeight, specialHeight);

    let to = document.querySelector(hash);
    if (to) animateScrollTo(to, 900, navbarHeight + specialHeight);
  };

  // Scroll on load
  API.tasks.ready.register(() => {
    if (location.hash.length > 0) API.scrollToHash(location.hash);
  });
}
